<template>
  <div>
    <custom-snackbar
      ref="snackbar"
    />

    <!-- Formulário -->
    <v-form ref="form" @submit.prevent="handleFormSubmit">
      <v-row>
        <v-col class="col-12 col-sm-8">
          <v-card class="mb-5">
            <v-card-title>
              Dados gerais
            </v-card-title>
            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-6">
                  <EmpresasSelect
                      obrigatorio
                      v-model="dadosGerais.empresa"
                      :disabled="allDisable"
                      @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <ProducoesSelect
                      obrigatorio
                      administrativo
                      :empresa="dadosGerais.empresa"
                      v-model="dadosGerais.producao"
                      :disabled="allDisable"
                      @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-text-field
                      dense
                      outlined
                      disabled
                      persistent-placeholder
                      v-model="dadosGerais.numeroSolicitacaoCompra"
                      label="N° SC"
                      @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-text-field
                      dense
                      outlined
                      disabled
                      persistent-placeholder
                      v-model="dadosGerais.numeroOrdemCompra"
                      @change="guardaLocalmenteFormulario"
                      label="N° OC"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-select
                    dense
                    outlined
                    persistent-placeholder
                    label="Situação"
                    :items="situacaoOptions"
                    item-text="label"
                    item-value="id"
                    disabled
                    v-model="dadosGerais.situacao"
                    @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <DatePicker
                    :label="dadosGerais.tipoCompra === 'paraEstoque' ? 'Data Solicitação' : 'Data Compra'"
                    v-model="dadosGerais.dataSolicitacao"
                    disabled
                  />
                </v-col>

                <v-col class="py-1 pb-4 col-12">
                  <v-radio-group v-model="dadosGerais.tipoCompra" row :rules="validacaoForm.tipoCompra">
                    <v-radio
                      label="Compra para estoque"
                      value="paraEstoque"
                      :disabled="allDisable"
                      @change="guardaLocalmenteFormulario"
                    />
                    <v-radio
                      label="Compra sem cotação"
                      value="semCotacao"
                      :disabled="allDisable"
                      @change="guardaLocalmenteFormulario"
                    />
                  </v-radio-group>
                </v-col>

                <v-col class="py-1 col-4" v-if="dadosGerais.tipoCompra === 'semCotacao'">
                  <v-select
                    dense
                    outlined
                    persistent-placeholder
                    label="Condições de Pagamento"
                    :items="condicoesDePagamentoOptions"
                    item-text="label"
                    item-value="id"
                    v-model="dadosGerais.condicoesPagamento"
                    :rules="[(v) => !!v || dadosGerais.tipoCompra === 'paraEstoque' || 'Condições de Pagamento Obrigatória']"
                    :disabled="allDisable"
                    @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-4" v-if="dadosGerais.tipoCompra === 'semCotacao'">
                  <DatePicker
                    label="Data Emissão NF"
                    v-model="dadosGerais.dataEmissaoNF"
                    :disabled="dadosGerais.tipoCompra === 'paraEstoque' || dadosGerais.tipoCompra === '' || allDisable"
                    :rules="[(v) => !!v || dadosGerais.tipoCompra === 'paraEstoque' || 'Data Emissão NF Obrigatória']"
                    @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-4" v-if="dadosGerais.tipoCompra === 'semCotacao'">
                  <DatePicker
                    label="Data Vencimento NF"
                    v-model="dadosGerais.dataVencimentoNF"
                    :disabled="dadosGerais.tipoCompra === 'paraEstoque' || dadosGerais.tipoCompra === '' || allDisable"
                    :rules="[(v) => !!v || dadosGerais.tipoCompra === 'paraEstoque' || 'Data Vencimento NF Obrigatória']"
                    :min="adicionaSeteDiasVencimentoNF"
                    @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 pb-5 col-12">
                  <v-textarea
                    outlined
                    persistent-placeholder
                    :label="dadosGerais.tipoCompra === 'semCotacao' ? 'Justificativa': 'Observações'"
                    value=""
                    v-model="dadosGerais.observacoes"
                    :rows="dadosGerais.tipoCompra === 'semCotacao' ? 2 : 5"
                    :rules="[(v) => !!v || dadosGerais.tipoCompra === 'paraEstoque' || 'Justificativa Obrigatória']"
                    :disabled="allDisable"
                    @change="guardaLocalmenteFormulario"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="col-12 col-sm-4">
          <v-card class="mb-5">
            <v-card-title>
              Responsáveis
            </v-card-title>

            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-12">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    persistent-placeholder
                    label="Solicitação"
                    v-model="responsaveis.solicitacao"
                    @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-12">
                  <UsuarioSelect
                      :empresa="dadosGerais.empresa"
                      :producao="dadosGerais.producao"
                      v-model="responsaveis.compra"
                      :obrigatorio="dadosGerais.tipoCompra === 'semCotacao'"
                      label="Compra"
                      :disabled="allDisable || dadosGerais.tipoCompra !== 'semCotacao' || dadosGerais.producao <= 0"
                      @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    persistent-placeholder
                    label="Aprovação Nv. 1"
                    v-model="responsaveis.aprovacaoNv1"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    persistent-placeholder
                    label="Aprovação Nv. 2"
                    v-model="responsaveis.aprovacaoNv2"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-5">
            <v-card-title>
              Fornecedor
            </v-card-title>

            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-12">
                  <FornecedorSelect
                    :empresa="dadosGerais.empresa"
                    :producao="dadosGerais.producao"
                    v-model="fornecedor.fornecedor"
                    :obrigatorio="dadosGerais.tipoCompra === 'semCotacao'"
                    :disabled="allDisable"
                    @change="guardaLocalmenteFormulario"
                  />
                </v-col>

                <v-col class="py-1 col-12">
                  <v-text-field
                    dense
                    outlined
                    persistent-placeholder
                    label="N° NF"
                    v-model="fornecedor.numeroNotaFiscal"
                    :rules="[(v) => !!v || dadosGerais.tipoCompra === 'paraEstoque' || 'N° NF Obrigatória']"
                    :disabled="allDisable"
                    @change="guardaLocalmenteFormulario"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Produtos -->
      <v-card class="mb-5">
        <v-card-title>
          Produtos
        </v-card-title>
        <v-card-text>
          <v-row
            v-for="(produto, index) in produtos"
            :key="'produtos-' + index"
            dense
          >
            <v-col md="3">
              <ProdutoSelect
                :nome="index"
                :empresa="dadosGerais.empresa"
                v-model="produto.produto"
                :disabled="!dadosGerais.empresa || allDisable"
                @change="produtoSelect(produto, index)"
                return-object
                obrigatorio
              />
            </v-col>
            <v-col>
              <BemPatrimonialSelect
                  :empresa="dadosGerais.empresa"
                  :producao="dadosGerais.producao"
                  v-model="produto.bemPatrimonial"
                  :disabled="!produto.produto || allDisable"
                  @change="produtoSelect(produto, index)"
                  return-object
              />
            </v-col>

            <v-col>
              <v-text-field
                outlined
                dense
                persistent-placeholder
                label="Cód. Fabricação"
                v-model="produto.codigoFabricacao"
                :disabled="!produto.produto || allDisable"
                @change="guardaLocalmenteFormulario"
              />
            </v-col>

            <v-col>
              <v-text-field
                outlined
                dense
                persistent-placeholder
                label="Modelo"
                v-model="produto.modelo"
                :disabled="!produto.produto || allDisable"
                @change="guardaLocalmenteFormulario"
              />
            </v-col>

            <v-col md="1">
              <v-text-field
                outlined
                dense
                persistent-placeholder
                label="Qtde"
                type="number"
                v-model.number="produto.quantidade"
                :disabled="!produto.produto || allDisable"
                @change="atualizaTotalProduto(produto, index)"
              />
            </v-col>

            <v-col md="1">
              <v-text-field
                outlined
                dense
                persistent-placeholder
                label="UN"
                v-model="produto.unidade"
                disabled
              />
            </v-col>

            <v-col md="1">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Vr. Unit (R$)"
                v-model="produto.valorUnitario"
                :disabled="!produto.produto || dadosGerais.tipoCompra === 'paraEstoque' || allDisable"
                @change="atualizaTotalProduto(produto, index)"
              />
            </v-col>

            <v-col md="1">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Total"
                v-model="produto.total"
                disabled
              />
            </v-col>

            <v-col class="my-1" md="auto">
              <v-btn
                icon
                small
                @click="produtosAddRow(index)"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn
                icon
                small
                @click="produtosDeleteRow(index)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          Totais
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col class="px-1 py-0 col-2">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Frete (R$)"
                v-model="totais.frete"
                :disabled="dadosGerais.tipoCompra === 'paraEstoque' || allDisable"
              />
            </v-col>
            <v-col class="px-1 py-0 col-2">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Outras Despesas (R$)"
                v-model="totais.outrasDespesas"
                :disabled="dadosGerais.tipoCompra === 'paraEstoque' || allDisable"
              />
            </v-col>
            <v-col class="px-1 py-0 col-2">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Desconto (R$)"
                v-model="totais.desconto"
                :disabled="dadosGerais.tipoCompra === 'paraEstoque' || allDisable"
              />
            </v-col>
            <v-col class="px-1 py-0 col-2">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Total Geral (R$)"
                v-model="totais.totalGeral"
                disabled
              />
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

      <!-- Barra de Ações -->
      <v-card class="mb-5">
        <v-card-text>
          <v-row class="p-2" justify="end">
            <v-btn
                class="mr-3"
                depressed
                outlined
                color="primary"
                @click="resetForm"
                :disabled="loadingSalvando"
            >
              {{ this.cadastrado ? 'Nova Solicitação' : 'Descartar Solicitação' }}
            </v-btn>
            <v-btn
                depressed
                color="primary"
                @click="handleFormSubmit"
                :disabled="loadingSalvando || allDisable"
            >
              Solicitar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      // Dados da Solicitação
      dadosGerais: {
        numeroSolicitacaoCompra: 0,
        numeroOrdemCompra: 0,
        dataSolicitacao: this.$date.getISOToday(),
        empresa: 0,
        producao: 0,
        tipoCompra: 'paraEstoque',
        condicoesPagamento: null,
        situacao: 'P',
        entregaTipo: null,
        dataEmissaoNF: null,
        dataVencimentoNF: null,
        observacoes: null,
      },

      responsaveis: {
        solicitacao: null,
        compra: null,
        aprovacaoNv1: null,
        aprovacaoNv2: null,
      },

      fornecedor: {
        fornecedor: null,
        numeroNotaFiscal: null,
      },

      showDeleteButton: false,

      produtos: [
        {
          produto: null,
          bemPatrimonial: { id: '', label: '' },
          codigoFabricacao: null,
          modelo: null,
          quantidade: 1,
          unidade: null,
          valorUnitario: null,
          total: null,
        },
      ],

      totais: {
        frete: 0,
        outrasDespesas: 0,
        desconto: 0,
        totalGeral: 0,
      },

      // Disable
      valorUnitarioDisabled: true,
      freteDisabled: true,
      outrasDespesasDisabled: true,
      descontoDisabled: true,
      produtosAddRowDisable: true,
      allDisable: false,
      cadastrado: false,
      carregado: false,
      loadingSalvando: false,
      guardaLocalmenteFormularioTimeout: null,

      condicoesDePagamentoOptions: [
        { id: '0001', label: 'À Vista' },
        { id: '0101', label: '1 PARC. (01 DIA PRAZO SEM ENTRADA)' },
        { id: '0105', label: '1 PARC. (05 DIAS PRAZO SEM ENTRADA)' },
        { id: '0107', label: '1 PARC. (07 DIAS PRAZO SEM ENTRADA)' },
        { id: '0110', label: '1 PARC. (10 DIAS PRAZO SEM ENTRADA)' },
        { id: '0115', label: '1 PARC. (15 DIAS PRAZO SEM ENTRADA)' },
        { id: '0120', label: '1 PARC. (20 DIAS PRAZO SEM ENTRADA)' },
        { id: '0121', label: '1 PARC. (21 DIAS PRAZO SEM ENTRADA)' },
        { id: '0125', label: '1 PARC. (25 DIAS PRAZO SEM ENTRADA)' },
        { id: '0128', label: '1 PARC. (28 DIAS PRAZO SEM ENTRADA)' },
        { id: '0130', label: '1 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '0135', label: '1 PARC. (35 DIAS PRAZO SEM ENTRADA)' },
        { id: '0140', label: '1 PARC. (40 DIAS PRAZO SEM ENTRADA)' },
        { id: '0145', label: '1 PARC. (45 DIAS PRAZO SEM ENTRADA)' },
        { id: '0160', label: '1 PARC. (60 DIAS PRAZO SEM ENTRADA)' },
        { id: '0190', label: '1 PARC. (90 DIAS PRAZO SEM ENTRADA)' },
        { id: '0215', label: '2 PARC. (15 DIAS PRAZO SEM ENTRADA)' },
        { id: '0220', label: '2 PARC. (20 DIAS PRAZO SEM ENTRADA)' },
        { id: '0225', label: '2 PARC. (25 DIAS PRAZO SEM ENTRADA)' },
        { id: '0228', label: '2 PARC. (28 DIAS PRAZO SEM ENTRADA)' },
        { id: '0230', label: '2 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '0245', label: '2 PARC. (45 DIAS PRAZO SEM ENTRADA)' },
        { id: '1210', label: '2 PARC. (ENTRADA + 10 DIAS PRAZO)' },
        { id: '1215', label: '2 PARC. (ENTRADA + 15 DIAS PRAZO)' },
        { id: '1220', label: '2 PARC. (ENTRADA + 20 DIAS PRAZO)' },
        { id: '1225', label: '2 PARC. (ENTRADA + 25 DIAS PRAZO)' },
        { id: '1228', label: '2 PARC. (ENTRADA + 28 DIAS PRAZO)' },
        { id: '1230', label: '2 PARC. (ENTRADA + 30 DIAS PRAZO)' },
        { id: '1245', label: '2 PARC. (ENTRADA + 45 DIAS PRAZO)' },
        { id: '0315', label: '3 PARC. (15 DIAS PRAZO SEM ENTRADA)' },
        { id: '0320', label: '3 PARC. (20 DIAS PRAZO SEM ENTRADA)' },
        { id: '0325', label: '3 PARC. (25 DIAS PRAZO SEM ENTRADA)' },
        { id: '0328', label: '3 PARC. (28 DIAS PRAZO SEM ENTRADA)' },
        { id: '0330', label: '3 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '1315', label: '3 PARC. (ENTRADA + 15 DIAS PRAZO)' },
        { id: '1320', label: '3 PARC. (ENTRADA + 20 DIAS PRAZO)' },
        { id: '1325', label: '3 PARC. (ENTRADA + 25 DIAS)' },
        { id: '1328', label: '3 PARC. (ENTRADA + 28 DIAS PRAZO)' },
        { id: '1330', label: '3 PARC. (ENTRADA + 30 DIAS PRAZO)' },
        { id: '0425', label: '4 PARC. (25 DIAS PRAZO SEM ENTRADA)' },
        { id: '0430', label: '4 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '1425', label: '4 PARC. (ENTRADA + 25 DIAS PRAZO)' },
        { id: '1430', label: '4 PARC. (ENTRADA + 30 DIAS PRAZO)' },
        { id: '0530', label: '5 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '1530', label: '5 PARC.(30 DIAS PRAZO SEM ENTRADA)' },
        { id: '0630', label: '6 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
      ],

      situacaoOptions: [
        { id: 'P', label: 'Pendente' },
        { id: 'F', label: 'Comprada' },
      ],

      validacaoForm: {
        empresa: [(v) => !!v || 'Empresa é obrigatória'],
        producao: [(v) => !!v || 'Produção é obrigatória'],
        bemPatrimonial: [(v) => !!v || 'Bem Patrimonial é obrigatório'],
        tipoCompra: [(v) => !!v || 'Tipo de Compra é obrigatório'],
        situacao: [(v) => !!v || 'Situação é obrigatória'],
      },
    }
  },

  computed: {
    ...mapGetters(['currentUserPersonalInfo']),
    adicionaSeteDiasVencimentoNF () {
      const d = new Date(this.dadosGerais.dataSolicitacao)
      d.setDate(d.getDate() + 8)
      return this.$stringFormat.dateToISO(d.toLocaleString())
    },
  },

  watch: {
    'dadosGerais.tipoCompra' (value) {
      this.$refs.form.resetValidation()
      this.dadosGerais.situacao = value === 'paraEstoque' ? 'P' : 'F'
    },

    'totais.frete' (value) {
      this.atualizaTotalGeral()
    },

    'totais.outrasDespesas' (value) {
      this.atualizaTotalGeral()
    },

    'totais.desconto' (value) {
      this.atualizaTotalGeral()
    },
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Compras' },
      { title: 'Solicitação de Compras', route: 'solicitacao-compras' },
    ])

    this.initialize()
  },

  methods: {
    initialize () {
      this.atualizaFormularioPrePreenchido()
      this.responsaveis.solicitacao = this.currentUserPersonalInfo.name?.toUpperCase()
    },
    resetForm () {
      window.localStorage.removeItem('compras_formulario')
      window.location.reload()
    },
    guardaLocalmenteFormulario (e) {
      if (!this.carregado) {
        return
      }

      clearTimeout(this.guardaLocalmenteFormularioTimeout)

      this.guardaLocalmenteFormularioTimeout = setTimeout(() => {
        const formularioCompras = {
          dadosGerais: this.dadosGerais,
          responsaveis: this.responsaveis,
          fornecedor: this.fornecedor,
          totais: this.totais,
          produtos: this.produtos,
        }

        window.localStorage.setItem('compras_formulario', JSON.stringify(formularioCompras))
      }, 1000)
    },
    atualizaFormularioPrePreenchido () {
      try {
        const formularioCompras = JSON.parse(window.localStorage.getItem('compras_formulario'))

        if (!formularioCompras) {
          this.carregado = true
          return
        }

        this.dadosGerais = formularioCompras.dadosGerais
        this.responsaveis = formularioCompras.responsaveis
        this.fornecedor = formularioCompras.fornecedor
        this.totais = formularioCompras.totais
        this.produtos = formularioCompras.produtos
        this.carregado = true
      } catch (e) {
      }
    },

    handleShowDelete () {
      this.showDeleteButton = !this.showDeleteButton
    },

    produtosAddRow (index) {
      if (!this.dadosGerais.producao) {
        this.$refs.snackbar.show('Alerta', 'Você deve selecionar uma Empresa e Produção', 'alert')
        return
      }

      this.produtos.splice(index+1, 0, {
        produto: null,
        bemPatrimonial: { id: '', label: '' },
        codigoFabricacao: null,
        modelo: null,
        quantidade: 1,
        unidade: null,
        valorUnitario: null,
        total: null,
      })
    },

    produtosDeleteRow (index) {
      this.produtos.splice(index, 1)

      if (this.produtos.length === 0) {
        this.produtosAddRow()
      }

      this.guardaLocalmenteFormulario()
    },

    produtoSelect (item, index) {
      this.produtos[index].unidade = (!item.produto) ? null : item.produto.unidade
      this.guardaLocalmenteFormulario()
    },

    atualizaTotalProduto (item, index) {
      this.produtos[index].total = this.$stringFormat.formatNumber(this.$stringFormat.convertToFloatNumber(item.valorUnitario) * item.quantidade)
      this.atualizaTotalGeral()
    },

    atualizaTotalGeral () {
      let total = 0
      total += this.$stringFormat.convertToFloatNumber(this.totais.frete)
      total += this.$stringFormat.convertToFloatNumber(this.totais.outrasDespesas)
      total -= this.$stringFormat.convertToFloatNumber(this.totais.desconto)

      for (const i in this.produtos) {
        total += this.$stringFormat.convertToFloatNumber(this.produtos[i].total)
      }

      this.totais.totalGeral = this.$stringFormat.formatNumber(total)
      this.guardaLocalmenteFormulario()
    },

    handleFormSubmit () {
      if (this.$refs.form.validate()) {
        this.loadingSalvando = true
        const tipoCompra = this.dadosGerais.tipoCompra === 'paraEstoque' ? 'Solicitação' : 'Ordem'

        this.$refs.snackbar.show(
          'Aguarde',
          `Realizando a ${tipoCompra} de Compra`,
          'success',
          -1,
          true,
        )

        const produtos = this.produtos.map((produto, index) => {
          return {
            sequencia_solicitacao: index + 1,
            produto_id: produto.produto.id,
            quantidade: produto.quantidade,
            valor: this.$stringFormat.convertToFloatNumber(produto.valorUnitario),
            bem_patrimonial: produto.bemPatrimonial ? produto.bemPatrimonial.id : '',
          }
        })

        ApiService
          .post('/compras/produtos', {
            para_estoque: this.dadosGerais.tipoCompra === 'paraEstoque',
            empresa_id: this.dadosGerais.empresa,
            producao_id: this.dadosGerais.producao,
            comprador_id: this.responsaveis.compra,
            data_vencimento_nf: this.dadosGerais.dataVencimentoNF,
            data_nf: this.dadosGerais.dataEmissaoNF,
            numero_nf: this.fornecedor.numeroNotaFiscal,
            condicao_pagamento: this.dadosGerais.condicoesPagamento,
            observacao: this.dadosGerais.observacoes,
            fornecedor_id: this.fornecedor.fornecedor,
            desconto: this.totais.desconto,
            outras_despesas: this.totais.outrasDespesas,
            frete: this.totais.frete,
            produtos,
          })
          .then(async (res) => {
            this.loadingSalvando = false

            if (!res.data.success) {
              return this.$refs.snackbar.show(
                'Erro',
                res.data.mensagem,
                'danger',
                3000,
              )
            }

            this.allDisable = true

            this.dadosGerais.numeroSolicitacaoCompra = res.data.codigo_sc ?? 0
            this.dadosGerais.numeroOrdemCompra = res.data.codigo_oc ?? 0

            this.$refs.snackbar.show(
              'Sucesso',
              `${tipoCompra} de Compra realizada com sucesso, número ${res.data.codigo_oc ?? ''}${res.data.codigo_sc ?? ''}`,
              'success',
            )

            this.cadastrado = true
            window.localStorage.removeItem('compras_formulario')
          })
          .catch((error) => {
            this.loadingSalvando = false

            this.$refs.snackbar.show(
              'Erro',
              `Não foi possível realizar a ${tipoCompra} de Compra`,
              'danger',
            )

            console.error('Erro: ', error)
          })
      }
    },
  },
}
</script>

<style>
  .v-input--selection-controls .v-radio > .v-label {
    margin-bottom: 0;
  }
  .v-input--selection-controls.v-input {
    margin-top: 0;
    padding-top: 0;
  }
</style>
